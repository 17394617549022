@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');

/* ----------------------------GLOBAL----------------------------- */
.header {
	text-align: center;
	font-size: 4rem;
	margin-bottom: 150px;
}

/* ----------------------------NAVBAR----------------------------- */
.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
}

.navbar_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    background-color: #191919;
    box-shadow: 0 0 10px black 0.5;
    font-family: 'Work Sans', sans-serif;
    padding: 20px 0 20px 0;
	position: fixed;
	transition: all 0.5s ease-out;
	flex-wrap: wrap;

	@media(max-width:768px) {
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;

		.menu_list {
			display: none !important;
		}
	}
}

.logo_container {
    display: inline;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 50%;
	flex-grow: 1;
}

.logo {
    max-width: 200px;
    height: 50px;
    padding: 0 50px 0 50px;
}

.navbar_toggle {
	display: none;

	@media(max-width:768px) {
		display: block;
		margin-left: auto;
		margin-right: 50px;
		flex-grow: 1;
		text-align: right;
	}
}

// menu slide in animation
@keyframes slideInLeft {
    0% {
        transform: translateY(-150%);
        visibility: visible;
    }
    100% {
        transform: translateY(0);
    }
}

.menu {
    display: flex;
    flex-direction: row;
	padding: 0px 50px 0px 50px;
    ul {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        float: left;
        
        li {
            .menu_item {
                color: rgb(200, 200, 200);
                text-decoration: none;
                padding: 0 10px 0 10px;
                transition: color,text-shadow 0.25s ease-out;
            }

            .menu_item:hover {
				color: rgb(250, 250, 250);
				text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
            }

            .item1 { animation-delay: 0.25s; }
            .item2 { animation-delay: 0.5s; }
            .item3 { animation-delay: 0.75s; }
            .item4 { animation-delay: 1.25s; }

        }
    }

	@media(max-width: 768px) {
		display: none;
	}
}

.toggle-button {
	position: absolute;
	top: 0.75rem;
	right: 1rem;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 30px;
	height: 21px;

	.bar {
		height: 3px;
		width: 100%;
		background-color: white;
		border-radius: 10px;
	}
}

.active {
	display: block;
	display: flex;
	flex-direction: column;
}

.drop_menu_hidden {
	display: none;
}

.drop_menu_show {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between !important;
	margin-top: 50px;
	flex: 2;
	transition: display 0.5s ease-out;

	li a {
		display: block;
		text-align: center;
		width: 25%;
		color: rgb(200, 200, 200);
		text-decoration: none;
		padding: 0 10px 0 10px;
		justify-content: center;
		padding: 10px 0 10px 0;
		transition: color,text-shadow 0.25s ease-out;

		&:hover {
			color: rgb(250, 250, 250);
			text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
		}
	}
}

.drop_menu_list {
	list-style-type: none;
	direction: vertical;
	display: block;
	padding: 0px;
}

/* ----------------------------ABOUT----------------------------- */
.about_container {
	width: 60%;
	padding: 100px 0 100px 0;

	@media(max-width: 768px) {
		width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.about_header {
		text-align: left;
		color: #f2f2f2;
		font-size: 10rem;
		.glow {
			animation: glow 4s ease-in-out 0.5s;
			@keyframes glow {
				0% {
				text-shadow: none;
				}
				50% {
				text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
				}
				100% {
				text-shadow: none;
				}
			}
		}

		@media(min-width: 769px) and (max-width: 1024px) {
			font-size: 7rem;
		}

		@media(max-width:768px) {
			font-size: 4rem;
			text-align: center;
		}
	}
	.about_me {
		width: 60%;
		font-size: 1.25rem;
		text-align: left;

		@media(min-width: 769px) and (max-width: 1024px) {
			font-size: 1.0rem;
		}

		@media(max-width: 768px) {
			font-size: 0.9rem;
		}
	}
}

.hidden {
	opacity: 0;
	filter: blur(5px);
	transform: translateX(-100%);
	transition: all 1s;
}

.about_me.hidden {
	transition: all 1s 0.5s; // adds 0.5s delay to the animation
}

@media(prefers-reduced-motion) {
	.hidden {
		transition: none;
	}
}

.show {
	opacity: 1;
	filter: blur(0);
	transform: translateX(0);
}

@media(max-width: 768px) {
	.about_container {
		width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.about_header {
		font-size: 4rem !important;
		text-align: center !important;
	}
	.about_me {
		font-size: 0.9rem !important;
	}
}

/* ----------------------------SKILLS----------------------------- */
.skills_container {
	width: 60%;
	padding: 100px 0 100px 0;
}

.skills_header {
	text-align: center;
	font-size: 4rem;
}

.skills_list {
	display: flex;
	flex-direction: column;
}

.list_header {
	text-align: center;
}

.list {
	list-style-type: none;
	justify-content: flex-start;
	padding-left: 0;
	display: flex;
	flex-direction: row;
	gap: 50px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	li:nth-child(1) {
		animation-delay: 0.25s;
	}
	li:nth-child(2) {
		animation-delay: 0.5s;
	}
	li:nth-child(3) {
		animation-delay: 0.75s;
	}
	li:nth-child(4) {
		animation-delay: 1s;
	}
	li:nth-child(5) {
		animation-delay: 1.25s;
	}
	li:nth-child(6) {
		animation-delay: 1.5s;
	}
	li:nth-child(7) {
		animation-delay: 1.75s;
	}
	li:nth-child(8) {
		animation-delay: 2s;
	}
}

.list_item {
	margin: 20px 0 20px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	gap: 50px;
}

.skill_level {
	width: 100%; /* Full width */
	background-color: #ddd; /* Grey background */
	border-radius: 10px;
}
  
.level {
	text-align: right; /* Right-align text */
	padding-top: 10px; /* Add top padding */
	padding-bottom: 10px; /* Add bottom padding */
	color: white; /* White text color */
	font-weight: 700;
	border-radius: 10px;
}

.list.framework {
	li:nth-child(1) {
		animation-delay: 0.25s;
	}
	li:nth-child(2) {
		animation-delay: 0.5s;
	}
	li:nth-child(3) {
		animation-delay: 0.75s;
	}
	li:nth-child(4) {
		animation-delay: 1s;
	}
}

.skill_icon {
	width: 120px;
	height: 120px;
	background-color: white;
	padding: 20px 20px 20px 20px;
	border-radius: 10px;
}

.skill {
	width: 160px;
	height: 160px;
	position: relative;
}

.outer {
	height: 160px;
	width: 160px;
	border-radius: 50%;
	box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.15),
	-6px -6px 10px -1px rbga(255,255,255,0.7);
	padding: 20px;
}

.inner {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#number {
	font-weight: 600;
	color: #f5f5f5;
}

circle {
	fill: none;
	stroke: url(#GradientColor);
	stroke-width: 20px;
	stroke-dasharray: 472;
	stroke-dashoffset: 472;
	
	&.HTML {
		animation: load_90 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.CSS {
		animation: load_75 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.JS {
		animation: load_50 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.CPP {
		animation: load_90 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.JAVA {
		animation: load_75 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.PYTHON {
		animation: load_25 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.REACT {
		animation: load_50 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.EXPRESS {
		animation: load_25 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.BOOTSTRAP {
		animation: load_25 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.NEXT {
		animation: load_25 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.NODE {
		animation: load_50 2s linear forwards;
		animation-delay: 1.5s;
	}
	&.MONGO {
		animation: load_25 2s linear forwards;
		animation-delay: 1.5s;
	}
}

@keyframes load_90 {
	100% {
		stroke-dashoffset: 100;
	}
}

@keyframes load_75 {
	100% {
		stroke-dashoffset: 150;
	}
}

@keyframes load_50 {
	100% {
		stroke-dashoffset: 250;
	}
}

@keyframes load_25 {
	100% {
		stroke-dashoffset: 375;
	}
}

.circle {
	position: absolute;
	top: 0;
	left: 0;
}

/* ----------------------------EXPERIENCE----------------------------- */
.exp_container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.exp_card_container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: 10px 10px 20px 10px;
	min-width: 100%;
}

.card {
	width: 70em;
	padding: 20px 0 20px 0;
	background: #171717;
	transition: 1s ease-in-out;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	display: flex;
	flex-direction: column;
	box-shadow:rgb(0, 0, 0) 5px 5px 10px;
	align-items: center;
	animation-delay: .25s;

	@media(max-width:768px) {
		width: 25em;
	}
}
  
.card span {
	font-weight: bold;
	color: white;
	text-align: center;
	display: block;
	font-size: 1em;
}
  
.card .info {
	font-weight: 400;
	color: white;
	display: block;
	text-align: center;
	font-size: 20px;
	margin: 1em;
	text-align: left;
	width: 80%;
}

.card .img {
	width: 6em;
	height: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	border-radius: 15px;
	margin: auto auto 10px auto;
}

.card .share {
	margin-top: 1em;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	gap: 1em;
}

.card a {
	color: white;
	transition: text-shadow 0.25s ease-out;
}

.card .button_container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.card .button {	
	padding: 10px 15px;	
	display: block;
	margin: auto;
	border-radius: 25px;
	border: none;
	font-weight: bold;
	background: #ffffff;
	color: rgb(0, 0, 0);
	transition: .25s ease-in-out;
	text-decoration: none;
	margin: 10px 10px 10px 10px;

	@media(max-width:768px) {
		padding: 5px 10px;
		font-size: 12px;
	}
}

.card .button:hover {
	background: black;
	color: white;
	cursor: pointer;
}

.card_header {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.company {
	font-size: 15px;
}

.share svg {
	width: 20px;
	height: 20px;
}

/* ----------------------------PROJECTS----------------------------- */
.project_card_container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 25px;
}

.project_card {
	width: 30em;

	@media(max-width: 768px) {
		width: 20em;
	}

	padding: 20px 10px 20px 10px;
	background: #171717;
	transition: 1s ease-in-out;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	display: flex;
	flex-direction: column;
	box-shadow:rgb(0, 0, 0) 5px 5px 10px;
	align-items: center;
	animation-delay: .25s;
	margin-bottom: 75px;

	&.card1 { animation-delay: 0.25s; }
	&.card2 { animation-delay: 0.5s; }
	&.card3 { animation-delay: 0.75s; }
}



.project_link svg {
	font-size: 50px;
}

.title {
	margin-bottom: 0px;
	font-size: 25px;
	margin-top: 0px;
	padding: 0 10px 0 10px;
}

.info {
	text-align: center;
}

.time_loc {
	font-size: 14px;
}

.video {
	width: inherit;
	margin-bottom: 1rem;
}

/* ----------------------------CONTACT----------------------------- */
.contact_container p {
	margin-bottom: 50px;
}

.contact_info {
	display: flex;
	gap: 20px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.contact_link {
	color: #f2f2f2;
	text-decoration: none;
	transition: all 0.25s ease-out;

	&:hover{
		color: rgb(250, 250, 250);
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
	}
}

/* ----------------------------MEDIA QUERY----------------------------- */
/* ABOUT */
/* SKILLS */
@media(max-width: 768px) {
	.skills_container {
		width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
/* EXPERIENCE */
@media(max-width: 768px) {
	.exp_container {
		width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
/* PROJECT */
@media(max-width: 768px) {
	.project_container {
		width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}/* CONTACT */
@media(max-width: 768px) {
	.contact_container {
		width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}