.App {
  text-align: center;
  background-color: #191919;
  color: rgb(215, 215, 215);
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  min-height: 90vh;
  min-width: 100vw;
  display: flex;
  align-items: center;;
  justify-content: center;
  margin-bottom: 150px;
}